<template>
  <div>
    <stripe-sepa-debit-form ref="stripe" v-if="state.public_key" @stripe-init="onInit" :account_id="state.account_id"
                            :billing_address="state.billing_address" :public_key="state.public_key" @on-error="onError"/>
  </div>
</template>

<script>
import {sendMessage, subscribe} from "@/utils/post-message";
import {EVENT_TYPE} from "@/utils/Event";
import StripeSepaDebitForm from "@/components/StripeSepaDebitForm";

export default {
  name: "StripeSepaDebit",
  components: {StripeSepaDebitForm},
  data() {
    return {
      state: {},
      events: []
    }
  },
  mounted() {
    const that = this
    // subscribe event send to
    this.events = [
      subscribe(this.getMsgType(EVENT_TYPE.fCreatePayloadAction), data => {
        that.$refs.stripe
          .createProviderPayload(data).then(v =>{
              sendMessage(this.getMsgType(EVENT_TYPE.fCreatePayloadAction), v)
            }
          ).catch(e => {
            sendMessage(this.getMsgType(EVENT_TYPE.fCreatePayloadAction), {
              success: false,
              payload: e
            })
          })
      }),
      subscribe(this.getMsgType(EVENT_TYPE.fConfirmPayment), async (data) => {
        await that.$refs.stripe
            .confirmPayment(data).then(v =>{
              sendMessage(this.getMsgType(EVENT_TYPE.fConfirmPayment), v)
            }
        ).catch(e => {
          sendMessage(this.getMsgType(EVENT_TYPE.fConfirmPayment), {
            success: false,
            error: e
          })
        })
      }),
      subscribe(this.getMsgType(EVENT_TYPE.fState), (data) => {
            that.$set(this, 'state', this.cloneDeep(data))
          }
      ),
    ]
    this.onInit();
  },
  beforeDestroy() {
    this.events.forEach(event => event())
  },
  methods: {
    onInit(k) {
      sendMessage(this.getMsgType(EVENT_TYPE.fStripeInit), k)
    },
    onError(e) {
      sendMessage(this.getMsgType(EVENT_TYPE.fOnError),e)
    },
    onLoading(isLoaded) {
      sendMessage(this.getMsgType(EVENT_TYPE.fOnLoading), isLoaded)
    },
    cloneDeep(original) {
      return Object.assign({}, original)
    },
    getMsgType:(type) => `${type}:sepa`
  }
}
</script>

<style>
  body {
    background-color: #f3f3f3;
  }
</style>
