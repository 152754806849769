export function setAllCookie(data) {
    const cookie = parseCookie(data)
    for (const key in cookie) {
        const value = cookie[key];
        document.cookie = `${key}=${value}; SameSite=None; Secure`
    }
}

export function parseCookie(cookieData) {
    let pairs = cookieData.split(';');
    let cookies = {};
    for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split('=');
        cookies[(pair[0] + '').trim()] = unescape(pair.slice(1).join('='));
    }
    return cookies;
}