export const injectPaypal = (src, cb) =>
    new Promise((resolve, reject) => {
      if (!src) {
        reject(new Error(`${src} injected`))
        return
      }
      const script = document.createElement('script')
      script.src = src
      script.onload = cb
      document.body.appendChild(script)
      script.addEventListener('load', () => {
        resolve()
      })
    })
