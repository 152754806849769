<template>
  <CreditCardForm
      v-show="!isFloat"
      :is-show-card-holder-name="!isCardholderNameHidden"
      :display-cvc-icon="displayCvcIcon"
      :error-card-holder-name="errorCardHolderName"
      :is-creator-package="isCreatorPackage"
      :is-mobile="isMobile"
      ref="cardForm"
      @form-height-change="onResize"
      @change-form-field="fieldChange"
  />
</template>
<script>
import {sendMessage, subscribe} from '@/utils/post-message'
import '../assets/styles/_payment-hosted-field.scss'
import {EVENT_TYPE} from '@/utils/Event'
import {injectStripe} from '@/utils/stripe-sdk';
import stripe_v2 from "@/api/stripe_v2";
import CreditCardForm from '@/components/CreditCardForm'
import {RESIZE} from "@/constant";
import { encryptData } from "@/utils/encrypt";
import {setAllCookie} from "@/utils/common";

export default {
  components: {
    CreditCardForm
  },
  data() {
    return {
      isOnePageCheckout: false,
      gatewayAccountIDSBasePayment: '',
      getSetting: {},
      isStripePaymentIntent: false,
      isStripeDestinationCharge: false,
      customCheckout: {},
      publicKey: '',
      state: {},
      events: [],
      stripe: null,
      errorCardHolderName: '',
      isFloat: false,
      isCardholderNameHidden: false,
      isCreatorPackage: false,
      smpPublicKey: '',
      isMobile: false,
      displayCvcIcon: false,
      paymentMethodId: '',
    }
  },
  mounted() {
    this.onLoading(true);
    document.body.style.background = "transparent";
    document.body.style.margin = "10px";
    this.events = [
      subscribe(EVENT_TYPE.fState, (data) => {
        this.$set(this, 'state', this.cloneDeep(data))
      }),
      subscribe(EVENT_TYPE.fCartNextAction, data => this.handleAuthorizeNextAction(data)),
      subscribe(EVENT_TYPE.fCreatePayloadAction, data => this.createProviderPayload(data)),
      subscribe(EVENT_TYPE.fConfirmPayment, data => this.confirmPayment(data)),
      subscribe(EVENT_TYPE.fCookies, data => {
          setAllCookie(data)
      })
    ]
    injectStripe(() => {
      this.initStripe()
    })
  },
  beforeDestroy() {
    this.events.forEach(event => event())
  },
  computed: {
    shouldEncryptPayload() {
      return !!this.smpPublicKey
    }
  },
  methods: {
    initStripe() {
      if (window.Stripe && this.publicKey && !this.stripe) {
        const stripeOption = {}
        if (this.gatewayAccountIDSBasePayment && !this.isStripeDestinationCharge) stripeOption.stripeAccount = this.gatewayAccountIDSBasePayment
        this.stripe = window.Stripe(this.publicKey, stripeOption)
      }
      this.onLoading(false);
    },
    async handleAuthorizeNextAction(clientSecret) {
      this.isFloat = true
      const {error, paymentIntent} = await this.stripe.handleCardAction(clientSecret)
      this.isFloat = false
      sendMessage(EVENT_TYPE.fCartNextAction, {error, paymentIntent})
    },

    async confirmPayment(clientSecret) {
      let handleActions = false
      const payload = {
        payment_method: this.paymentMethodId,
      };
      let response = await this.stripe.confirmCardPayment(clientSecret, payload, {
        handleActions
      })

      if (response.paymentIntent && response.paymentIntent.next_action?.type === 'use_stripe_sdk') {
        this.isFloat = true
        handleActions = true;
        response = await this.stripe.confirmCardPayment(clientSecret, payload, {
          handleActions
        })
        this.isFloat = false
      }

      sendMessage(EVENT_TYPE.fConfirmPayment, { error: response.error, paymentIntent: response.paymentIntent })
    },

    async createProviderPayload(data) {
      this.$refs['cardForm'].isType = false
      this.$refs['cardForm'].submitted = true
      try {
        if (await this.$refs['cardForm'].validateForm()) {
          throw new Error('Validation error')
        }

        const card = {
          number: this.$refs['cardForm'].cardNumber,
          cvc: this.$refs['cardForm'].cvc,
          month: this.$refs['cardForm'].expMonth.toString(),
          year: this.$refs['cardForm'].expYear.toString()
        }
        const rawData = {
          key: this.publicKey,
          gateway_account_id: this.gatewayAccountIDSBasePayment,
          billing_address: data.billing_address,
          checkout_token: this.state.checkoutToken,
          payment_method_id: data.payment_method_id,
        }

        let req = rawData
        if (this.shouldEncryptPayload) {
          req = {
            ...rawData,
            card_encrypted: encryptData(this.smpPublicKey, JSON.stringify(card))
          }
        } else {
          req = {
            ...rawData,
            card,
          }
        }

        const response = await stripe_v2.createPaymentProviderPayload(this.state.shopDomain, req)

        if (response.refresh_public_key) {
          sendMessage(EVENT_TYPE.fCreatePayloadAction, {
            success: false,
            refreshPublicKey: true
          })
        } else if (response.error) {
          throw new Error(response.error)
        } else {
          const provider = {
            payment_method_id: response.result.PaymentMethodId,
            token: response.result.TokenID
          }
          sendMessage(EVENT_TYPE.fCreatePayloadAction, provider)
          this.paymentMethodId = response.result.PaymentMethodId
        }
      } catch (e) {
        sendMessage(EVENT_TYPE.fCreatePayloadAction, {
          success: false,
          payload: e
        })
      }
    },
    onInit(k) {
      sendMessage(EVENT_TYPE.fStripeInit, k)
    },
    fieldChange() {
      sendMessage(EVENT_TYPE.fFieldChange, [...arguments])
    },
    onResize(height) {
      sendMessage(EVENT_TYPE.fOnResize, height + RESIZE.MARGIN_FORM)
    },
    onLoading(isLoaded) {
      sendMessage(EVENT_TYPE.fOnLoading, isLoaded)
    },
    handle3dSecure(src) {
      sendMessage(EVENT_TYPE.threeDimensionalSecure, src)
    },
    cloneDeep(original) {
      return Object.assign({}, original)
    },
  },
  watch: {
    publicKey: {
      handler() {
        this.initStripe()
      }
    },
    smpPublicKey: {
      handler() {
        this.initStripe()
      }
    },
    state: {
      immediate: true,
      handler(state) {
        this.billingDetails = state.billingDetails
        this.isStripeDestinationCharge = state.isStripeDestinationCharge
        this.isOnePageCheckout = state.isOnePageCheckout
        this.gatewayAccountIDSBasePayment = state.gatewayAccountIDSBasePayment
        this.checkoutSetting = state.checkoutSetting
        this.isStripePaymentIntent = state.isStripePaymentIntent
        this.publicKey = state.publicKey
        this.isCardholderNameHidden = !!state.isCardholderNameHidden
        this.isCreatorPackage = !!state.isCreatorPackage
        this.isMobile = state.isMobile
        this.smpPublicKey = state.smpPublicKey
          this.displayCvcIcon = !!state.displayCvcIcon
      }
    },
    isFloat: {
      handler(bol) {
        sendMessage(EVENT_TYPE.threeDimensionalSecure, bol)
      },
      deep: true
    },
    cardHolderName: {
      handler(val) {
        this.errorCardHolderName = val ? '' : this.state?.validations?.cardHolderName || 'Please enter your name as it appears on the card.'
      }
    }
  }
}
</script>
