<template>
  <div :class="['s-input-wrapper']">
    <div :class="[wrapperClasses, { 'is-show-floating-label': floatingLabel && !!internalValue }, {'is-active': isHighlight}]">
      <label class="s-label" v-if="floatingLabel" :for="$attrs.id">
        {{ placeholder }}
      </label>
      <input
        v-if="type !== 'textarea'"
        ref="input"
        :class="['s-input__inner', { 'is-invalid': isInvalid }, customClass]"
        :type="type"
        :value="internalValue"
        :autocomplete="autocomplete"
        :maxlength="maxlength"
        v-bind="$attrs"
        @input="onInput"
        @blur="onBlur"
        @focus="onFocus"
        :placeholder="placeholder"
      >

      <textarea
        v-else
        ref="textarea"
        class="s-textarea__inner"
        :class="customClass"
        :maxlength="maxlength"
        :value="internalValue"
        :placeholder="placeholder"
        v-bind="$attrs"
        @input="onInput"
        @blur="onBlur"
        @focus="onFocus"
      />
    </div>
    <ValidationMessages v-if="validations" :validations="validations" />
  </div>
</template>

<script>
import FormElementMixin from './FormElementMixin'
import ValidationMessages from './ValidationMessages'

export default {
  name: 'SInput',
  inheritAttrs: false,
  mixins: [FormElementMixin],
  components: {
    ValidationMessages
  },
  props: {
    // eslint-disable-next-line
    value: [String, Number],
    type: {
      type: String,
      default: 'text'
    },
    customClass: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'medium'
    },
    validations: {
      type: Array,
      default: () => []
    },
    floatingLabel: {
      type: Boolean,
      default: true
    },
    isHighlight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    wrapperClasses () {
      return [
        this.size ? `is-${this.size}` : '',
        this.type === 'textarea' ? 's-textarea' : 's-input',
        {
          'is-loading': this.loading,
          'is-expanded': this.expanded
        }
      ]
    },
    isInvalid () {
      return this.validations.some(validation => validation.condition)
    }
  },
  data () {
    return {
      internalValue: this.value,
      elementRef: this.type === 'textarea' ? 'textarea' : 'input'
    }
  },
  methods: {
    onInput (event) {
      this.$nextTick(() => {
        this.internalValue = event.target.value
      })
    }
  },
  watch: {
    value (val) {
      this.internalValue = val
    },

    internalValue (val) {
      this.$emit('input', val)
      !this.isValid && this.checkHtml5Validity()
    }
  }
}
</script>
