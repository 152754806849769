import 'unfetch/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './routes'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import VueCookies from 'vue-cookies'

Vue.config.productionTip = process.env.NODE_ENV !== 'production'
const messages = {
  en: {

  }
}

Vue.use(VueI18n)
Vue.use(VueRouter)
Vue.use(VueCookies)

const i18n = new VueI18n({
  locale: 'en',
  messages
})

if (window.self !== window.top) {
  new Vue({
    render: h => h(App),
    router,
    i18n
  }).$mount('#app')
}
