<template>
  <div v-if="validations">
    <p
      v-for="(validation, index) in validations"
      :key="index"
      class="field-message field-message--error"
    >
      <template v-if="validation.condition">
        {{ validation.text }}
      </template>
    </p>
  </div>
</template>
<script>
export default {
  props: {
    validations: {
      type: Array,
      default: () => []
    }
  }
}
</script>
