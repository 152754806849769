<template>
  <div :class="['content-box__row--secondary', customClass]"
       :style="isCardholderNameHidden ? styleIframeWithoutCardholderName : styleIframe"
       v-show="!isFloat">
    <div class="field">
      <div id="stripe-card-number" class="StripeElement--with-icon" />
      <div class="field__icon">
        <div class="field__icon-svg">
          <lock-icon-outline></lock-icon-outline>
        </div>
      </div>
      <p class="field-message field-message--error" v-if="errors && errors.cardNumber">
        {{ errors.cardNumber }}
      </p>
    </div>
    <div class="field field--input-custom" v-if="!isCardholderNameHidden">
      <s-input
        class="field--card-input"
        v-model="additionalData.name"
        @input="clearValidate('name')"
        @blur="onBlurCardholderName"
        :placeholder="placeholders.cardHolderName"
        :floating-label="false"
        :validations="[
          {
            condition: errors && errors.nameAdditional,
            text: errors.nameAdditional,
          },
        ]"
      />
    </div>

    <div class="field field--half" :class="{ 'field--half-mobile': isOnePageCheckout }">
      <div id="stripe-card-expiry" />
      <p class="field-message field-message--error" v-if="errors && errors.cardExpiry">
        {{ errors.cardExpiry }}
      </p>
    </div>

    <div class="field field--half" :class="{ 'field--half-mobile': isOnePageCheckout }">
      <div id="stripe-card-cvc" class="StripeElement--with-icon" />
      <div class="field__icon has-tooltip">
        <span class="tooltip">
          <span>
            {{ tooltip.cardExpiry }}
          </span>
        </span>
        <div class="field__icon-svg">
          <help-icon-outline></help-icon-outline>
        </div>
      </div>
      <p class="field-message field-message--error" v-if="errors && errors.cardCvc">
        {{ errors.cardCvc }}
      </p>
    </div>
  </div>
</template>

<script>
import SInput from './Input'
import HelpIconOutline from './HelpIconOutline'
import LockIconOutline from './LockIconOutline'
import {PAYMENT_METHOD_TYPES} from '@/constant'
import {injectStripe} from '../utils/stripe-sdk'
import {RESIZE} from '../constant'

export default {
  name: 'PaymentStripeFrameForm',
  components: {
    SInput,
    HelpIconOutline,
    LockIconOutline
  },
  props: {
    state: {
      type: Object,
      default: () => ({})
    },
    fields: {
      type: Object,
      default: () => ({})
    },
    placeholders: {
      type: Object,
      default: () => ({})
    },
    validations: {
      type: Object,
      default: () => ({})
    },
    tooltip: {
      type: Object,
      default: () => ({})
    },
    publicKey: {
      type: String,
      default: ''
    },
    provider: {
      type: String,
      default: ''
    },
    gateway: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    styleIframe () {
      return {
        height: `${this.height}px`
      }
    },
    styleIframeWithoutCardholderName () {
      return {
        height: `${this.heightWithoutCardholderName}px`
      }
    },
    isValid () {
      return Object.values(this.errors).filter(Boolean).length === 0
    }
  },
  data () {
    return {
      isOnePageCheckout: false,
      gatewayAccountIDSBasePayment: '',
      getSetting: {},
      isStripePaymentIntent: false,
      isStripeDestinationCharge: false,
      isCardholderNameHidden: false,
      customCheckout: {},
      //
      stripe: null,
      isSubmitted: false,
      isStripeInit: true,
      elements: {
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null
      },
      additionalData: {
        name: ''
      },
      validateAdditionalRules: {
        name: this.$t('checkout_and_system.form.card_holder_name')
      },
      errors: {
        nameAdditional: ''
      },
      style: {
        base: {
          fontSize: '14px',
          '::placeholder': {
            color: '#777777'
          }
        },
        invalid: {
          color: '#222222'
        }
      },
      isFloat: false,
      loading: true,
      height: RESIZE.TOTAL_HEIGHT,
      heightWithoutCardholderName: RESIZE.TOTAL_HEIGHT_WITHOUT_CARDHOLDER_NAME,
      metadata: {
        checkout_token: ''
      }
    }
  },
  mounted () {
    this.loading = true
    injectStripe(() => {
      this.initStripe()
    })
    this.initStripe()
    this.metadata.checkout_token = this.state.checkoutToken
    this.$nextTick(() => {
      const privateStripeElementInputs = this.$el.querySelectorAll('.__PrivateStripeElement-input')
      if (privateStripeElementInputs) {
        privateStripeElementInputs.forEach(input => {
          input.addEventListener('focus', function () {
            this.blur()
          })
        })
      }

      const privateStripeElementSafariInputs = this.$el.querySelectorAll('.__PrivateStripeElement-safariInput')
      if (privateStripeElementSafariInputs) {
        privateStripeElementSafariInputs.forEach(input => {
          input.addEventListener('focus', function () {
            this.blur()
          })
        })
      }
    })
    this.loading = false
  },
  methods: {
    initStripe () {
      if (window.Stripe && this.publicKey && !this.stripe) {
        const stripeOption = {}
        if (this.gatewayAccountIDSBasePayment && !this.isStripeDestinationCharge) stripeOption.stripeAccount = this.gatewayAccountIDSBasePayment
        this.stripe = window.Stripe(this.publicKey, stripeOption)
        const elements = this.stripe.elements()

        // Set font-family to match checkout setting
        const checkoutSetting = this.checkoutSetting
        if (checkoutSetting && this.style && checkoutSetting.checkout_body_font && this.style.base) {
          this.style.base.fontFamily = checkoutSetting.checkout_body_font
        }

        Object.entries(this.fields).forEach(field => {
          const [key, val] = field
          const element = elements.create(
            key,
            {...val.options, style: this.style} || {}
          )

          element.mount(`#stripe-${val.id}`)
          element.on('change', e => {
            this.$set(
              this.errors,
              key,
              e.error && e.error.code ? this.validations[e.error.code] : ''
            )
            this.emitTrackingChange(key, e.empty, e.complete)
          })
          element.on('ready', () => {
            this.isStripeInit = false
            this.$emit('stripe-init', key)
          })
          this.$set(this.elements, key, element)
        })
      }
    },

    onResize () {
      this.height = document.getElementsByTagName('html')[0].scrollHeight
      this.$emit('on-resize', this.height)
    },

    createProviderPayload () {
      return new Promise((resolve, reject) => {
        if (!this.stripe) {
          reject(new Error('Network error'))
          return
        }
        this.validate()

        /*
        Object.keys(this.errors).forEach((key) => {
          if (this.errors[key]) {
            return reject(new Error('Validation error'))
          }
        })
        */
        Object.keys(this.errors).forEach((key) => {
          if (this.errors[key]) {
            reject(new Error('Validation error'))
            return
          }
        });

        const provider = {}
        this.createPaymentMethodAction().then(({paymentMethod, error}) => {
          if (error) {
            reject(error)
          } else {
            provider.payment_method_id = paymentMethod.id
          }
          this.stripe
            .createToken(this.elements.cardNumber, this.additionalData)
            .then((result) => {
              if (result?.token) {
                provider.token = result.token.id
                resolve(provider)
              } else {
                if (result?.error?.type === 'validation_error') {
                  reject(new Error('Validation error'))
                  return
                }
                reject(result)
              }
            })
        })
      })
    },

    /**
     * Create payment method for payment intent
     * @returns {Promise<{paymentMethod: *, error: *}>}
     */
    async createPaymentMethodAction () {
      const {
        paymentMethod,
        error
      } = await this.stripe.createPaymentMethod('card', this.elements.cardNumber, {billing_details: this.billingDetails, metadata: this.metadata})
      return {paymentMethod, error}
    },

    async confirmPayment(clientSecret) {
      let handleActions = false
      const payload = {
        payment_method: {
          card: this.elements.cardNumber,
          billing_details: {
            name: this.billingDetails.name,
            email: this.billingDetails.email,
          },
        },
      };

      let response = await this.stripe.confirmCardPayment(clientSecret, payload, {
        handleActions
      })
      if (response.paymentIntent && response.paymentIntent.next_action?.type === 'use_stripe_sdk') {
        handleActions = true
        this.isFloat = true
        response = await this.stripe.confirmCardPayment(clientSecret, payload)
        this.isFloat = false
      }

      return { error: response.error, paymentIntent: response.paymentIntent }
    },

    /**
     * Handle card next action with 3D secure
     * @param {String} clientSecret
     * @returns {Promise<{paymentIntent: *, error: *}>}
     */
    async handleAuthorizeNextAction (clientSecret) {
      this.isFloat = true
      const {error, paymentIntent} = await this.stripe.handleCardAction(clientSecret)
      this.isFloat = false
      return {error, paymentIntent}
    },

    createToken () {
      return new Promise(async (resolve, reject) => {
        if (!this.stripe) {
          reject(new Error('Stripe not initialized'))
          return
        }
        // this.validate()
        // Object.keys(this.errors).forEach(key => {
        //   if (this.errors[key]) {
        //     return reject(new Error('Validation error'))
        //   }
        // })

        this.isSubmitted = true
        const provider = {
          payment_method_type: PAYMENT_METHOD_TYPES.TypeCreditCard
        }
        if (this.isStripePaymentIntent) {
          const {paymentMethod, error} = await this.createPaymentMethod()

          if (error) {
            reject(error)
          } else {
            provider.payment_method_id = paymentMethod.id
          }
        }
        this.stripe
          .createToken(this.elements.cardNumber, this.additionalData)
          .then(result => {
            if (result && result.token) {
              provider.token = result.token.id
              resolve(provider)
            } else {
              if (
                result &&
                result.error &&
                result.error.type === 'validation_error'
              ) {
                reject(new Error('Validation error'))
                return
              }
              reject(result)
            }
          })
      })
    },

    validate () {
      for (let field in this.validateAdditionalRules) {
        if (
          this.additionalData.hasOwnProperty(field) &&
          this.validateAdditionalRules.hasOwnProperty(field)
        ) {
          let message = ''
          if (field === 'name') {
            message = !this.additionalData[field].length && !this.isCardholderNameHidden
              ? this.validations.cardHolderName
              : ''
          } else {
            message = !this.additionalData[field].length
              ? `Your ${this.validateAdditionalRules[field]} is incomplete`
              : ''
          }
          this.$set(this.errors, `${field}Additional`, message)
        }
      }
    },

    clearValidate (field) {
      const key = `${field}Additional`

      if (this.errors[key]) {
        this.$set(this.errors, key, '')
      }
    },

    onBlurCardholderName () {
      // this.validate()

      const isEmpty = !this.additionalData.name
      this.emitTrackingChange('CardholderName', isEmpty, !isEmpty)
      this.onResize()
    },

    emitTrackingChange (name, empty, complete) {
      this.$emit('stripe-form-field-change', name, empty, complete)
    },

    maxTotalHeight (height, maxHeight) {
      if (height > maxHeight) {
        height = maxHeight
      }
      return height
    },

    heightDefaultError (height) {
      height += RESIZE.DEFAULT_HEIGHT_ERROR
      return this.maxTotalHeight(height, RESIZE.MAX_HEIGHT)
    }
  },
  watch: {
    isFloat: {
      handler (bol) {
        this.$emit('3d-secure', bol)
      },
      deep: true
    },
    loading: {
      handler (isLoaded) {
        this.$emit('loading', isLoaded)
      },
      deep: true
    },
    errors: {
      handler () {
        let totalHeight = this.isCardholderNameHidden ? RESIZE.TOTAL_HEIGHT_WITHOUT_CARDHOLDER_NAME : RESIZE.TOTAL_HEIGHT
        if (this.errors.cardCvc) {
          totalHeight = this.heightDefaultError(totalHeight)
        }
        if (this.errors.cardExpiry) {
          totalHeight = this.heightDefaultError(totalHeight)
        }
        if (this.errors.cardNumber) {
          totalHeight = this.heightDefaultError(totalHeight)
        }

        if (this.errors.nameAdditional && this.errors.nameAdditional !== '') {
          totalHeight = this.heightDefaultError(totalHeight)
        }

        if (this.isCardholderNameHidden) {
          this.heightWithoutCardholderName = this.maxTotalHeight(totalHeight, RESIZE.TOTAL_MAX_HEIGHT)
          this.$emit('on-resize', this.heightWithoutCardholderName)
          return
        }
        this.height = this.maxTotalHeight(totalHeight, RESIZE.TOTAL_MAX_HEIGHT)
        this.$emit('on-resize', this.height)
      },
      deep: true
    },
    publicKey: function () {
      this.initStripe()
      this.$set(this, 'errors', {})
    },
    state: {
      immediate: true,
      handler (state) {
        this.billingDetails = state.billingDetails
        this.isStripeDestinationCharge = state.isStripeDestinationCharge
        this.isOnePageCheckout = state.isOnePageCheckout
        this.gatewayAccountIDSBasePayment = state.gatewayAccountIDSBasePayment
        this.checkoutSetting = state.checkoutSetting
        this.isStripePaymentIntent = state.isStripePaymentIntent
        this.isCardholderNameHidden = !!state.isCardholderNameHidden
      }
    }
  }
}
</script>
