<template>
  <CreditCardForm
      ref="cardForm"
      @form-height-change="onResize"
  />
</template>

<script>
import {
  PAYPAL_PRO_RESIZE,
  PAYPAL_PRO_SUBMITTED,
  PAYPAL_PRO_3DS_SETUP,
  PAYPAL_PRO_3DS_RESPONSE
} from '@/constant'
import paypalpro from '@/api/paypalpro'
import CreditCardForm from "@/components/CreditCardForm";
import {EVENT_TYPE} from "@/utils/Event";
import {setAllCookie} from '@/utils/common'


export default {
  name: 'PaypalPro',
  components: {
    CreditCardForm
  },
  computed: {
    eventAccepted () {
      return new Map([
        [PAYPAL_PRO_SUBMITTED, this.submitPaypal]
      ])
    },
  },
  methods: {
    async handler ({data, source, origin}) {
      const {type, shopId, checkoutToken} = data
        if(type === EVENT_TYPE.fCookies) {
            setAllCookie(data.payload)
        }
      try {
        if (!this.eventAccepted.has(type)) return
        if (!checkoutToken) {
          return source.postMessage(this.normalizeData({type, error: 'Missing checkout token'}), origin)
        }
        if (!shopId) {
          return source.postMessage(this.normalizeData({type, error: 'Missing shopId'}), origin)
        }
        return source.postMessage(this.normalizeData({type, ...await this.eventAccepted.get(type)(data)}), origin)
      } catch (error) {
        source.postMessage(this.normalizeData({type, error: error.message}), origin)
      }
    },
    normalizeData (data) {
      return JSON.parse(JSON.stringify(data))
    },
    async submitPaypal ({shopId, checkoutToken, billingAddress, isSameAddress, amount, currency, secureToken, enable3ds, hostname}) {
      this.$refs['cardForm'].isType = false
      this.$refs['cardForm'].submitted = true
      if (await this.$refs['cardForm'].validateForm()) {
        throw new Error('Validation error')
      }

      const response = await paypalpro.createCard(hostname, shopId, checkoutToken, {
        is_using_same_address: !!isSameAddress,
        billing_address: billingAddress,
        account_number: this.$refs['cardForm'].cardNumber,
        expired_date: this.$refs['cardForm'].expDate,
        cvv: this.$refs['cardForm'].cvc
      })
      if (response.error_message) {
        throw new Error(response.error_message)
      }
      const cardToken = response.result && response.result.card_token
      if (!enable3ds) return {card_id: cardToken}

      if (!secureToken || secureToken.length < 1) {
        throw new Error('Invalid secure token')
      }

      return new Promise((resolve, reject) => {
        this.callback = ({data: {type, ...data}}) => {
          if (type !== PAYPAL_PRO_3DS_RESPONSE) return
          window.removeEventListener('message', this.callback)
          return data.error ? reject(new Error(data.error)) : resolve(data)
        }
        window.addEventListener('message', this.callback, false)
        // Setup 3Ds
        window.parent.frames['secure-iframe'].postMessage({
          type: PAYPAL_PRO_3DS_SETUP,
          amount: amount,
          currency: currency,
          secureToken: secureToken,
          cardNumber: this.$refs['cardForm'].cardNumber,
          expiredMonth: this.$refs['cardForm'].expMonth,
          expiredYear: this.$refs['cardForm'].expYear,
          cardToken
        }, `${window.location.protocol}//${window.location.host}`)
      })
    },
    onResize(height) {
      window.parent.postMessage({type: PAYPAL_PRO_RESIZE, height}, '*')
    }
  },
  data () {
    return {
    }
  },
  mounted () {
    this.handler = this.handler.bind(this)
    document.body.style.background = "transparent";
    window.addEventListener('message', this.handler, false)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.callback)
  }
}
</script>
