export default {
  request (method, url = '', data, options) {
    if (url.search('https://') < 0 && url.search('http://') < 0) {
      const apiDomainEndpoint = process.env.VUE_APP_PAYMENT_ENDPOINT || `${window.location.protocol}//${window.location.host}`

      if (url[0] === '/') {
        url = apiDomainEndpoint + url
      } else {
        url = `${apiDomainEndpoint}/${url}`
      }
    }

    return fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      ...options
    }).then(response => response.json())
  },
  get (url, options = {}) {
    return this.request('get', url, {}, options)
  },
  post (url, data, options = {}) {
    return this.request('post', url, data, options)
  },
  put (url, data, options = {}) {
    return this.request('put', url, data, options)
  },
  delete (url, data = {}, options = {}) {
    return this.request('delete', url, data, options)
  }
}
