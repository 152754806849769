export const PAYPAL_PRO_SUBMITTED = 'PAYPAL_PRO_SUBMITTED'
export const PAYPAL_PRO_3DS_SETUP = 'PAYPAL_PRO_3DS_SETUP'
export const PAYPAL_PRO_3DS_SHOW_MODAL = 'PAYPAL_PRO_3DS_SHOW_MODAL'
export const PAYPAL_PRO_3DS_HIDE_MODAL = 'PAYPAL_PRO_3DS_HIDE_MODAL'
export const PAYPAL_PRO_3DS_RESPONSE = 'PAYPAL_PRO_3DS_RESPONSE'
export const PAYPAL_PRO_RESIZE = 'PAYPAL_PRO_RESIZE'

export const PAYMENT_METHOD_TYPES = {
  TypeCreditCard: 'credit-card',
  TypePaypal: 'paypal',
  TypeSepaDebit: 'sepa_debit',
  TypeBancontact: 'bancontact',
  TypeGiropay: 'giropay',
  TypeIdeal: 'ideal',
  TypeCOD: 'cod',
  TypeBankTransfer: 'bank_transfer'
}

export const RESIZE = {
  TOTAL_HEIGHT: 190,
  TOTAL_HEIGHT_WITHOUT_CARDHOLDER_NAME: 130,
  MAX_HEIGHT: 270,
  TOTAL_MAX_HEIGHT: 300,
  DEFAULT_HEIGHT_ERROR: 27,
  MARGIN_FORM: 20,
}

export const PAYMENT_GATEWAY = {
  PaypalPro: 'paypal-pro',
  Shopbase: 'platform',
  Test: 'test-gateway',
  Stripe: 'stripe',
  Braintree: 'braintree',
  CheckoutCom: 'checkout-com',
  Cardpay: 'cardpay',
  PaypalExpress: 'paypal-express',
  Cod: 'cod',
  BankTransfer: 'bank_transfer',
}
