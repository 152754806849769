export default {
  AED: {multiply: 100},
  AFN: {multiply: 100},
  ALL: {multiply: 100},
  AMD: {multiply: 100},
  ANG: {multiply: 100},
  AOA: {multiply: 100},
  ARS: {multiply: 100},
  AUD: {multiply: 100},
  AWG: {multiply: 100},
  AZN: {multiply: 100},
  BAM: {multiply: 100},
  BBD: {multiply: 100},
  BDT: {multiply: 100},
  BGN: {multiply: 100},
  BIF: {multiply: 1},
  BMD: {multiply: 100},
  BND: {multiply: 100},
  BOB: {multiply: 100},
  BRL: {multiply: 100},
  BSD: {multiply: 100},
  BWP: {multiply: 100},
  BZD: {multiply: 100},
  CAD: {multiply: 100},
  CDF: {multiply: 100},
  CHF: {multiply: 100},
  CNY: {multiply: 100},
  COP: {multiply: 100},
  CRC: {multiply: 100},
  CVE: {multiply: 100},
  CZK: {multiply: 100},
  DJF: {multiply: 1},
  DKK: {multiply: 100},
  DOP: {multiply: 100},
  DZD: {multiply: 100},
  EGP: {multiply: 100},
  ETB: {multiply: 100},
  EUR: {multiply: 100},
  FJD: {multiply: 100},
  FKP: {multiply: 100},
  GBP: {multiply: 100},
  GEL: {multiply: 100},
  GIP: {multiply: 100},
  GMD: {multiply: 100},
  GNF: {multiply: 1},
  GTQ: {multiply: 100},
  GYD: {multiply: 100},
  HKD: {multiply: 100},
  HNL: {multiply: 100},
  HRK: {multiply: 100},
  HTG: {multiply: 100},
  HUF: {multiply: 100},
  IDR: {multiply: 100},
  ILS: {multiply: 100},
  INR: {multiply: 100},
  ISK: {multiply: 100},
  JMD: {multiply: 100},
  JPY: {multiply: 1},
  KES: {multiply: 100},
  KGS: {multiply: 100},
  KHR: {multiply: 100},
  KMF: {multiply: 1},
  KRW: {multiply: 1},
  KYD: {multiply: 100},
  KZT: {multiply: 100},
  LAK: {multiply: 100},
  LBP: {multiply: 100},
  LKR: {multiply: 100},
  LRD: {multiply: 100},
  LSL: {multiply: 100},
  MAD: {multiply: 100},
  MDL: {multiply: 100},
  MGA: {multiply: 1},
  MKD: {multiply: 100},
  MNT: {multiply: 100},
  MOP: {multiply: 100},
  MUR: {multiply: 100},
  MVR: {multiply: 100},
  MWK: {multiply: 100},
  MXN: {multiply: 100},
  MYR: {multiply: 100},
  MZN: {multiply: 100},
  NAD: {multiply: 100},
  NGN: {multiply: 100},
  NIO: {multiply: 100},
  NOK: {multiply: 100},
  NPR: {multiply: 100},
  NZD: {multiply: 100},
  PAB: {multiply: 100},
  PEN: {multiply: 100},
  PGK: {multiply: 100},
  PHP: {multiply: 100},
  PKR: {multiply: 100},
  PLN: {multiply: 100},
  PYG: {multiply: 1},
  QAR: {multiply: 100},
  RON: {multiply: 100},
  RSD: {multiply: 100},
  RUB: {multiply: 100},
  RWF: {multiply: 1},
  SAR: {multiply: 100},
  SBD: {multiply: 100},
  SCR: {multiply: 100},
  SEK: {multiply: 100},
  SGD: {multiply: 100},
  SHP: {multiply: 100},
  SLL: {multiply: 100},
  SOS: {multiply: 100},
  SRD: {multiply: 100},
  SVC: {multiply: 100},
  SZL: {multiply: 100},
  THB: {multiply: 100},
  TJS: {multiply: 100},
  TOP: {multiply: 100},
  TRY: {multiply: 100},
  TTD: {multiply: 100},
  TWD: {multiply: 100},
  TZS: {multiply: 100},
  UAH: {multiply: 100},
  UGX: {multiply: 1},
  USD: {multiply: 100},
  UYU: {multiply: 100},
  UZS: {multiply: 100},
  VND: {multiply: 1},
  VUV: {multiply: 1},
  WST: {multiply: 100},
  XAF: {multiply: 1},
  XCD: {multiply: 100},
  XOF: {multiply: 1},
  XPF: {multiply: 1},
  YER: {multiply: 100},
  ZAR: {multiply: 100},
  ZMW: {multiply: 100}
}
