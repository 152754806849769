<template>
  <div ref="three-d-secure-wrapper"></div>
</template>

<script>
import {
  PAYPAL_PRO_3DS_SETUP,
  PAYPAL_PRO_3DS_HIDE_MODAL,
  PAYPAL_PRO_3DS_SHOW_MODAL,
  PAYPAL_PRO_3DS_RESPONSE
} from '../constant'
import currencyMapper from '../core/CurrencyMapper'

export default {
  name: 'ThreeDSecure',
  computed: {
    eventAccepted () {
      return new Map([
        [PAYPAL_PRO_3DS_SETUP, this.setupSecure]
      ])
    },
    currencyMapper () {
      return currencyMapper
    }
  },
  data () {
    return {
      countRetry: 0
    }
  },
  methods: {
    async handler ({data, source, origin}) {
      const {type} = data
      try {
        if (!this.eventAccepted.has(type)) return
        return source.postMessage(this.normalizeData({type: PAYPAL_PRO_3DS_RESPONSE, ...await this.eventAccepted.get(type)(data)}), origin)
      } catch (error) {
        return source.postMessage(this.normalizeData({type: PAYPAL_PRO_3DS_RESPONSE, error: error.message}), origin)
      }
    },
    normalizeData (data) {
      return JSON.parse(JSON.stringify(data))
    },
    async setupSecure ({amount, currency, secureToken, cardNumber, expiredMonth, expiredYear, cardToken}) {
      if (!secureToken) {
        throw new Error('Missing secure token')
      }
      if (!cardNumber) {
        throw new Error('Missing card number')
      }

      if (!expiredMonth) {
        throw new Error('Missing expired month')
      }

      if (!expiredYear) {
        throw new Error('Missing expired year')
      }

      if (!secureToken || secureToken.length < 1) {
        throw new Error('Invalid secure token')
      }
      let orderObj = {
        Consumer: {
          Account: {
            AccountNumber: cardNumber,
            ExpirationMonth: expiredMonth,
            ExpirationYear: expiredYear
          }
        },
        OrderDetails: {
          Amount: parseInt((amount * (this.currencyMapper[currency.toUpperCase()] ? this.currencyMapper[currency.toUpperCase()].multiply : 1)).toFixed(2)),
          CurrencyCode: currency
        }
      }

      this.Cardinal.setup('init', {
        jwt: secureToken
      }).then(() => {
        this.Cardinal.start('cca', orderObj)
      })

      return new Promise((resolve, reject) => {
        this.Cardinal.off('payments.validated')
        this.Cardinal.on('payments.validated', async (res, jwt) => {
          clearInterval(this.timeoutShowModal)
          window.parent.postMessage({type: PAYPAL_PRO_3DS_HIDE_MODAL}, '*')
          const errorNumberDisplay = res.ErrorNumber ? `(${res.ErrorNumber})` : ''

          if (!res.Payment || !res.Payment.ExtendedData) {
            // case 5
            if (res.ErrorNumber === 10002 || res.ErrorNumber === 10001) {
              return resolve({card_id: cardToken, secure_token: jwt})
            }
            return reject(new Error(`3D-Authentication failed. Cannot authorise this card. (Error response)`))
          }

          const paymentExtended = res.Payment.ExtendedData

          if (res.ActionCode === 'SUCCESS') {
            if (paymentExtended.Enrolled === 'Y' && paymentExtended.PAResStatus === 'A') {
              // case 4
              return reject(new Error('Validation error'))
            }
            // case 1,4
            return resolve({card_id: cardToken, secure_token: jwt})
          }

          if (res.ActionCode === 'NOACTION') {
            // case 11
            if (paymentExtended.Enrolled === 'Y' && paymentExtended.PAResStatus === 'U') {
              if (this.countRetry++ === 0) {
                return this.setupSecure({amount, currency, secureToken, cardNumber, expiredMonth, expiredYear}).then(resolve).catch(reject)
              }
              return resolve({card_id: cardToken, secure_token: jwt})
            }

            // case 7,8,9
            if (paymentExtended.Enrolled === 'U') {
              return resolve({card_id: cardToken, secure_token: jwt})
            }

            // case 12
            if (paymentExtended.Enrolled === 'B') {
              return resolve({card_id: cardToken, secure_token: jwt})
            }

            // case 6
            if (paymentExtended.Enrolled === 'N') {
              return resolve({card_id: cardToken, secure_token: jwt})
            }
          }

          // case 10
          if (res.ErrorNumber === 1050) {
            return reject(new Error(`General Error (Processor error - An error occurred during authorization. If these errors persist, contact merchant service provider).`))
          }

          // case 3 cancel 3ds
          if (res.ActionCode === 'FAILURE' && paymentExtended.Enrolled === 'Y' && paymentExtended.PAResStatus === 'N' && paymentExtended.SignatureVerification === 'Y') {
            return reject(new Error('Validation error'))
          }

          return reject(new Error(`3D-Authentication failed. Cannot authorise this card. ${errorNumberDisplay}`.trim()))
        })
      })
    }
  },
  mounted () {
    // Inject Cardinal SDK
    const isSandbox = this.$route.query.sandbox === 'true'
    const logEnv = this.$route.query.env

    let jsUrl = 'https://songbird.cardinalcommerce.com/edge/v1/songbird.js'
    if (isSandbox) {
      jsUrl = 'https://songbirdstag.cardinalcommerce.com/edge/v1/songbird.js'
    }
    let docHead = document.getElementsByTagName('head')[0]
    let docScript = document.createElement('script')
    docScript.type = 'text/javascript'
    docScript.src = jsUrl
    docScript.onload = () => {
      // Configure Cardinal log level
      if (!('Cardinal' in window)) {
        throw new Error('Cardinal namespace is not available. Please check the script Url.')
      }
      let logLevel = 'verbose'
      const isProd = logEnv === 'production'
      if (isProd) {
        logLevel = 'off'
      }
      this.Cardinal = window.Cardinal
      this.Cardinal.configure({ logging: { level: logLevel } })
      this.Cardinal.on('ui.render', () => {
        this.timeoutShowModal = setTimeout(() => {
          window.parent.postMessage({type: PAYPAL_PRO_3DS_SHOW_MODAL}, '*')
        }, 1100)
      })
    }
    docHead.appendChild(docScript)

    this.handler = this.handler.bind(this)
    window.addEventListener('message', this.handler, false)
  },
  beforeDestroy () {
    this.Cardinal.off('payments.setupComplete')
  }
}
</script>
