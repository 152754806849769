<template>
  <div id="iban-element"/>
</template>

<script lang="js">

import {injectStripe} from "@/utils/stripe-sdk";
import {PAYMENT_METHOD_TYPES} from "@/components/constants";

export default {
  name: "StripeSepaDebitForm",
  props: {
    account_id: String,
    public_key: String,
    billing_address: Object
  },
  data() {
    return {
      stripe: null,
      fields: {
        iban: {
          id: 'iban-element',
          options: {
            supportedCountries: ['SEPA'],
            // Elements can use a placeholder as an example IBAN that reflects
            // the IBAN format of your customer's country. If you know your
            // customer's country, we recommend passing it to the Element as the
            // placeholderCountry.
            placeholderCountry: 'NL',
          },
        },
      },
      style: {
        base: {
          color: '#222222',
          fontSize: '16px',
          '::placeholder': {
            color: '#777777',
          },
          ':-webkit-autofill': {
            color: '#222222',
          },
        },
        invalid: {
          color: '#222222',
          iconColor: '#222222',
          ':-webkit-autofill': {
            color: '#222222',
          },
        },
      },
      elements: {
        iban: null
      },
      errors: []
    }
  },
  mounted() {
    injectStripe(this.initStripe.bind(this))
    this.initStripe()
  },
  methods: {
    initStripe() {
      if (window.Stripe && this.public_key) {
        if (!this.stripe) {
          const account_id = this.account_id;
          const stripeOption = {};
          if (account_id) {
            stripeOption.stripeAccount = account_id;
          }
          this.stripe = window.Stripe(this.public_key, stripeOption);
          const elements = this.stripe.elements();

          Object.entries(this.fields).forEach((field) => {
            const [key, val] = field;
            const element = elements.create(key, {...val.options, style: this.style} || {});

            element.mount(`#${val.id}`);
            element.on('change', (e) => {
              this.$emit('on-error',e.error && e.error.message ? e.error.message : '');
            });
            element.on('ready', () => {
              this.$emit('stripe-init', key)
            });
            this.elements[key] = element;
          });
        }
      }
    },
    async createPaymentMethod() {
      const {paymentMethod, error} = await this.stripe.createPaymentMethod({
        type: PAYMENT_METHOD_TYPES.TypeSepaDebit,
        billing_details: this.billing_address,
        sepa_debit: this.elements.iban,
      });
      return {paymentMethod, error};
    },

    async confirmPayment(clientSecret) {
      const payload = {
        payment_method: {
          sepa_debit: this.elements.iban,
          billing_details: {
            name: this.billing_address.name,
            email: this.billing_address.email,
          },
        },
      }

      const response = await this.stripe.confirmSepaDebitPayment(
          clientSecret,
          payload,
      );

      if (response.error) {
        return {success: false, error: response.error}
      }

      return { paymentIntent: response.paymentIntent, success: true };
    },

    createProviderPayload() {
      return new Promise((resolve, reject) => {
        if (!this.stripe) {
          reject(new Error('Stripe not initialized'));
          return;
        }

        Object.keys(this.errors).forEach((key) => {
          if (this.errors[key]) {
            return reject(new Error('Validation error'));
          }
        });

        const provider = {
          payment_method_type: PAYMENT_METHOD_TYPES.TypeSepaDebit,
          payment_method_id: '',
        };
        this.createPaymentMethod().then(({paymentMethod, error}) => {
          if (error) {
            reject(error);
          } else {
            provider.payment_method_id = paymentMethod.id;
          }
          resolve(provider);
        });
      });
    }
  },
  watch: {
    public_key(val) {
      if (val) {
        this.initStripe()
      }
    }
  }
}
</script>

<style scoped>

</style>
