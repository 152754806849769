import http from "@/core/http";

export default {

    createOrder(payload) {
        return http.post(`/api/checkout/${payload.checkoutToken}/${payload.provider}/create-order.json`, {
            order_request: payload.orderRequest,
            payment_method_id: payload.paymentMethodId,
        })
    },

    handleResponseError(payload) {
        return http.post(`/api/checkout/checkout-error-monitor.json`, { payload })
    },

    savePaymentExpress(payload) {
        return http.post(`/checkout/${payload.checkoutToken}/fetch-payment-provider-order-info.json?disable_sync_address=${payload.disableSyncAddress}`,{
            body: {
                customer_id: payload.customer_id,
                email: payload.email,
            },
        })
    }
}