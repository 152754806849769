let source = null
const events = new Map()
let buffers = []

window.addEventListener(
    'message',
    event => {
        const rawdata = event.data
        if (typeof rawdata !== 'object') return
        const type = rawdata.type
        // eslint-disable-next-line no-console
        if (typeof type === 'string' && type.startsWith('wakeup')) {
            const type_parts = type.split(':')
            type_parts[0] = 'frame:ready'
            source = event.source
            sendMessage(type_parts.join(':'))
            const temp = buffers
            buffers = []
            temp.forEach(v => sendMessage(v.type, v.data))
            return
        }
        if (events.has(type)) {
            events.get(type).forEach(fn => fn(rawdata.data))
        }
    })

const targetOrigin = process.env.TARGET_ORIGIN || '*'

export function sendMessage(type, data) {
    if (source) {
        const dataClone = data ? JSON.parse(JSON.stringify(data)) : data
        source.postMessage({ type, data: dataClone }, targetOrigin)
    } else {
        buffers.push({type, data})
    }
}

export function subscribe(event, fn) {
    let set
    if (events.has(event)) {
        set = events.get(event)
    } else {
        set = new Set()
        events.set(event, set)
    }
    set.add(fn)

    return () => set.delete(fn)
}
