export const EVENT_TYPE = {
  fReady: 'frame:ready',
  fState: 'frame:state',
  fOnResize: 'frame:on-resize',
  fOnLoading: 'frame:on-loading',
  threeDimensionalSecure: '3d-secure',
  fFieldChange: 'frame:field-change',
  fStripeInit: 'frame:stripe-init',
  fOnError: 'frame:on-error',
  fCreatePayloadAction: 'frame:create-payload-action',
  fCreatePaymentMethodAction: 'frame:create-payment-method-action',
  fCartNextAction: 'frame:handle-card-next-action',
  fConfirmPayment: 'frame:confirm-payment',
  fCreatedToken: 'frame:created-token',
  fCustomStyle: 'frame:custom-style',
  fPaypalLoaded: 'frame:paypal-loaded',
  fPaypalApprove: 'frame:paypal-approved',
  fPaypalFail: 'frame:paypal-fail',
  fCookies: 'frame:cookie'
}
