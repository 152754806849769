<template>
  <div ref="smartButtonRef" class="smart-button">
    <div class="smart-button__label">
      Choose one of the following methods to complete your order
    </div>
    <div
        v-if="showOthers"
        @click="forceInitSmartButton"
        class="smart-button__other-options"
        v-show="!loading"
    >
      <chevron-left></chevron-left>
      <span>Other options</span>
    </div>
    <div :class="[isExpress ? '' : 'smart-button__iframe']">
      <div class="paypal-smart-button smart-button__logo">
        <div :id="id"></div>
        <div class="mt8" v-if="isPaypalPaylater" data-pp-message></div>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronLeft from './ChevronLeft.vue';
import { injectPaypal } from '@/utils/paypal-sdk';
import { PAYMENT_GATEWAY } from "@/constant";
import api from "@/api/paypal";
import { sendMessage, subscribe } from "@/utils/post-message";
import { EVENT_TYPE } from "@/utils/Event";

let indexId = 0;

const paypalButtonStyles = {
  paypal: {
    color: 'gold',
    size: 'responsive',
  },
  paylater: {
    color: 'gold',
    size: 'responsive',
  },
  card: {
    color: 'black',
    size: 'responsive',
  },
};


export default {
  name: 'PaypalSmartButton',
  components: {
    ChevronLeft
  },
  computed: {
    funding() {
      const result = ['paypal'];

      if (this.isPaypalPaylater) {
        result.push('paylater');
      }

      if (!this.isExpress) {
        result.push('card');
      }

      return result;
    },
    isPaypalPaylater() {
      return this.providerOptions.enable_paypal_paylater;
    },
    jsUrl() {
      let url = `https://www.paypal.com/sdk/js?client-id=${this.providerOptions.client_id}&intent=authorize&currency=${this.currencyCode}&locale=en_US`;
      if (this.providerOptions.merchant_id) {
        url = `${url}&merchant-id=${this.providerOptions.merchant_id}`;
      }
      if (this.isPaypalPaylater) {
        url = `${url}&enable-funding=paylater&components=messages,buttons`;
      }
      return url;
    },
  },
  data () {
    return {
      id: '',
      state: {},
      events: [],
      initialed: false,
      loading: this.isExpress,
      showOthers: false,
      checkoutToken: '',
      orderRequest: {},
      paymentMethod: {},
      isExpress: false,
      currencyCode: '',
      providerOptions: {},
      resizeObserver: null,
    }
  },
  created() {
    this.id = 'paypal-smart-button' + indexId++;
  },

  mounted () {
    this.loading = true
    this.events = [
      subscribe(EVENT_TYPE.fState, (data) => {
        this.$set(this, 'state', this.cloneDeep(data))
      }),
    ]
    sendMessage(EVENT_TYPE.fReady)
    injectPaypal(this.jsUrl, () => {
      this.initPaypal()
    })
    this.loading = false

    this.resizeObserver = new ResizeObserver(() => this.onResize());
    this.resizeObserver.observe(this.$refs.smartButtonRef);
  },

  beforeDestroy () {
    this.events.forEach(event => event())
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },

  methods: {
    cloneDeep (original) {
      return Object.assign({}, original)
    },

    forceInitSmartButton() {
      const button = document.getElementById(this.id);
      if (!button) {
        return;
      }
      button.innerHTML = '';
      this.initialed = false;
      this.loading = true;
      this.initPaypal();
    },

    initPaypal() {
      if (
          !this.initialed &&
          window &&
          window.paypal &&
          window.paypal.Buttons &&
          document.getElementById(this.id)
      ) {
        const that = this
        const callbacks = {
          onInit: function () {
            that.loading = false;
            that.initialed = true;
            sendMessage( EVENT_TYPE.fPaypalLoaded, {
              type:  that.paymentMethod?.type,
              id:  that.paymentMethod?.id,
            });
          },
          onClick: function (data) {
            that.showOthers = data.fundingSource === 'card';
          },
          createOrder: async function () {
            const response = await api.createOrder(
                {
                  provider: PAYMENT_GATEWAY.PaypalExpress,
                  orderRequest: that.orderRequest,
                  paymentMethodId: that.paymentMethod.id,
                  checkoutToken: that.checkoutToken,
                }
            );
            if (!response.id) {
              sendMessage(EVENT_TYPE.fPaypalApprove, {
                message: response.message,
                code: response.code,
              });
            } else {
              return response.id;
            }

          },
          onApprove: async function ({ orderID }) {
            sendMessage(EVENT_TYPE.fPaypalApprove, {
              order_id: orderID,
            })
            return true;
          },
          onError: async function (err) {
            await api.handleResponseError({
              error: {
                response: err,
              },
              endpoint: 'PayPal',
            });
          },
        };
        this.funding.forEach((fundingSource) => {
          const button = window.paypal.Buttons({
            ...callbacks,
            fundingSource: fundingSource,
            enableStandardCardFields: true,
            style: paypalButtonStyles[fundingSource],
          });

          if (button.isEligible()) {
            button.render(`#${this.id}`);
          }
        });
      }
    },

    async onResize() {
      await this.$nextTick(() => {
        if (this.$refs.smartButtonRef) {
          sendMessage(EVENT_TYPE.fOnResize, this.$refs.smartButtonRef.clientHeight);
        }
      })
    }
  },

  watch: {
    state: {
      immediate: true,
      handler(state) {
        this.orderRequest = this.cloneDeep(state.orderRequest);
        this.paymentMethod = this.cloneDeep(state.paymentMethod);
        this.providerOptions = this.cloneDeep(state.providerOptions);
        this.checkoutToken = state.checkoutToken;
        this.isExpress = state.isExpress;
        this.currencyCode = state.currencyCode;
        injectPaypal(this.jsUrl, () => {
          this.initPaypal()
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.smart-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f3f3;
  &__label {
    margin-top: 20px;
    margin-bottom: 16px;
  }

  &__other-options {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;
    span {
      font-size: 12px;
      margin-left: 5px;
      color: rgb(119, 119, 119);
    }
  }

  &__iframe {
    display: flex;
    justify-content: center;
    min-height: 148px;
    width: auto;
  }

  &__logo {
    width: 300px;
  }
}
@media (min-width: 500px) {
  .smart-button__iframe {
    width: 400px;
  }
}

@media (max-width: 476px) {
  .smart-button__label {
    width: 370px;
  }
}

@media (max-width: 402px) {
  .smart-button__label {
    width: 263px;
  }
}

@media (max-width: 313px) {
  .smart-button__label {
    width: auto;
  }
}

.mt8 {
  margin-top: 8px;
}
</style>
