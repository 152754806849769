<template>
  <PaymentStripeFrameForm
    v-if="state.publicKey"
    ref="stripe"
    :state="state"
    :provider="state.provider"
    :public-key="state.publicKey"
    :gateway="state.gateway"
    :fields="state.fields"
    :placeholders="state.placeholders"
    :validations="state.validations"
    :tooltip="state.tooltip"
    :custom-class="classess"
    @stripe-init="onInit"
    @stripe-form-field-change="fieldChange"
    @on-resize="onResize"
    @loading="onLoading"
    @3d-secure="handle3dSecure"
  />
</template>
<script>
import PaymentStripeFrameForm from './FrameForm'
import {sendMessage, subscribe} from '@/utils/post-message'
import {setAllCookie} from '@/utils/common'
import '../assets/styles/_payment-hosted-field.scss'
import {EVENT_TYPE} from '@/utils/Event'

export default {
  components: {
    PaymentStripeFrameForm
  },
  data() {
    return {
      state: {},
      events: [],
      classess: '',
    }
  },
  mounted() {
    const that = this
    // subscribe event send to
    this.events = [
      subscribe(EVENT_TYPE.fState, (data) =>
        that.$set(this, 'state', this.cloneDeep(data))
      ),
      subscribe(EVENT_TYPE.fCreatedToken, () => {
        that.$refs.stripe
            .createToken()
            .then(v => sendMessage(EVENT_TYPE.fCreatedToken, {success: true, payload: v}))
            .catch(e => {
              sendMessage(EVENT_TYPE.fCreatedToken, {
                success: false,
                payload: e
              })
            })
      }),
      subscribe(EVENT_TYPE.fCartNextAction, data => that.$refs.stripe
          .handleAuthorizeNextAction(data).then(v =>
              sendMessage(EVENT_TYPE.fCartNextAction, v)
          )),
      subscribe(EVENT_TYPE.fConfirmPayment, data => that.$refs.stripe
          .confirmPayment(data).then(v =>
          sendMessage(EVENT_TYPE.fConfirmPayment, v)
      )),
      subscribe(EVENT_TYPE.fCreatePaymentMethodAction, data => that.$refs.stripe
          .createPaymentMethodAction(data).then(v =>
              sendMessage(EVENT_TYPE.fCreatePaymentMethodAction, v)
          ).catch(e => {
            sendMessage(EVENT_TYPE.fCreatePaymentMethodAction, {
              success: false,
              payload: e
            })
          })),
      subscribe(EVENT_TYPE.fCreatePayloadAction, data => that.$refs.stripe
        .createProviderPayload(data).then(v =>
          sendMessage(EVENT_TYPE.fCreatePayloadAction, v)
        ).catch(e => {
          sendMessage(EVENT_TYPE.fCreatePayloadAction, {
            success: false,
            payload: e
          })
        })),
        subscribe(EVENT_TYPE.fCustomStyle, () => {
          this.classess = 'content-box__row--secondary-v2';
        }),
        subscribe(EVENT_TYPE.fCookies, data => {
            setAllCookie(data)
        })
    ];
    sendMessage(EVENT_TYPE.fReady)
  },
  beforeDestroy() {
    this.events.forEach(event => event())
  },
  methods: {
    onInit(k) {
      sendMessage(EVENT_TYPE.fStripeInit, k)
    },
    fieldChange() {
      sendMessage(EVENT_TYPE.fFieldChange, [...arguments])
    },
    onResize(d) {
      sendMessage(EVENT_TYPE.fOnResize, d)
    },
    onLoading(isLoaded) {
      sendMessage(EVENT_TYPE.fOnLoading, isLoaded)
    },
    handle3dSecure(src) {
      sendMessage(EVENT_TYPE.threeDimensionalSecure, src)
    },
    cloneDeep(original) {
      const copied = Object.assign({}, original)
      return copied
    },

  }
}
</script>

<style lang="scss">
$color: red;
</style>
