import ThreeDSecure from './components/ThreeDSecure'
import VueRouter from 'vue-router'
import PaypalPro from './components/PaypalPro'
import Stripe from './components/Stripe'
import StripeV2 from './components/StripeV2'
import StripeSepaDebit from "./components/StripeSepaDebit";
import PaypalSmartButton from "./components/PaypalSmartButton";

const routes = [
    {
        path: '/',
        name: 'card-info',
        component: PaypalPro
    },
    {
        path: '/three_d_secure',
        name: 'three-d-secure',
        component: ThreeDSecure
    },
    {
        path: '/stripe',
        name: 'stripe',
        component: Stripe
    },
    {
        path: '/opaysp/stripe',
        name: 'stripe',
        component: Stripe
    },
    {
        path: '/stripe_sepa_debit',
        name: 'stripe_sepa_debit',
        component: StripeSepaDebit
    },
    {
        path: '/opaysp/stripe_sepa_debit',
        name: 'stripe_sepa_debit',
        component: StripeSepaDebit
    },
    {
        path: '/stripe_v2',
        name: 'stripe-v2',
        component: StripeV2
    },
    {
      path: '/paypal-express',
      name: 'paypal-express',
      component: PaypalSmartButton
    },
    {
      path: '/opaysp/paypal-express',
      name: 'opaysp-paypal-express',
      component: PaypalSmartButton
    }
]

const router = new VueRouter({
    routes,
    mode: 'history'
})

export default router
